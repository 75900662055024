import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { Form } from 'antd'

import { AppContext } from '../../context/AppContext'
import { getClaimType, updateClaimType } from '../../network/API'

import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

const ClaimTypeEdit = () => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    const { id } = useParams()

    const fields = [
        {
            name: ['name'],
            label: 'Name',
            row: 1,
            col: 1,
            size: 2,
        }
    ]

    const details = useQuery(
        [
            'getClaimType',
            {
                id: id,
                token: token.token,
            },
        ],
        getClaimType
    )

    return (
        <PageContent
            title={'Edit value'}
        >
            {details.isError ? (
                <Error retry={() => details.refetch()} />
            ) : details.isLoading ? (
                <Loader />
            ) : (
                <FormBlock
                    id={id}
                    form={form}
                    fields={fields}
                    onSuccess={'/claim-types/list'}
                    onCancel={'/claim-types/list'}
                    data={details.data}
                    request={updateClaimType}
                    invalidate={[
                        'getClaimType'
                    ]}
                ></FormBlock>
            )}
        </PageContent>
    )
}

export default ClaimTypeEdit