import { useState, useContext, useEffect } from 'react'
import { Button, Form, DatePicker } from 'antd'
import moment from 'moment/moment'
import FileSaver from 'file-saver'

import { AppContext } from '../context/AppContext'
import { getClaims } from '../network/API'
import PageContent from '../components/PageContent'

const ExportCSV = () => {
    const [claims, setClaims] = useState(null)
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()

    useEffect(() => {
        if (!claims) {
            return
        }

        let str = 'Username,Claim Name,Total Price,Submit Date,Status,Transaction Date,Type,Price,Description'

        for (const claim of claims) {
            str += '\n' + claim.user?.username + ',' +
                claim.name + ',' +
                claim.totalPrice + ',' +
                moment(claim.createdAt).format('DD/MM/YYYY') + ',' +
                claim.status + ','

            claim.claimDetails.map((details, index) => {
                if (index !== 0) {
                    str += '\n,,,,,'
                }

                str += moment(details?.date).format('DD/MM/YYYY') + ',' +
                    details?.type?.name + ',' +
                    details?.price + ',' +
                    details?.description?.replace(/\n|\r/g, ' ')
            })
        }

        let exportContent = '\uFEFF'
        let blob = new Blob([exportContent + str], {
            type: 'text/plain;charset=utf-8'
        })
        FileSaver.saveAs(blob, 'Claims.csv')
    }, [claims])

    const loadClaims = (startTime, endTime) => {
        getClaims({
            queryKey: [
                'getClaims',
                {
                    token: token.token,
                    filter: {
                        createdAt: { $gte: startTime, $lte: endTime }
                    }
                }
            ]
        }).then((data) => {
            setClaims(data)
        })
    }

    const onFinish = (values) => {
        const startTime = new Date(
            values.dates[0].toDate().setHours(0, 0, 0, 0)
        )
        const endTime = new Date(
            values.dates[1].toDate().setHours(23, 59, 59, 999)
        )

        loadClaims(startTime, endTime)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <PageContent
            title={'CSV export settings'}
        >
            <Form
                form={form}
                colon={false}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={'Date range'}
                    name={'dates'}
                    style={{
                        gridColumn: '1 / 3',
                    }}
                >
                    <DatePicker.RangePicker format={'DD/MM/YYYY'} />
                </Form.Item>
                <Form.Item
                    style={{
                        gridColumn: '1 / 1',
                    }}
                >
                    <Button type='primary' htmlType='submit'>Export CSV</Button>
                </Form.Item>
            </Form>
        </PageContent>
    )
}

export default ExportCSV