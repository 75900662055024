import { Form } from 'antd'

import { createUser } from '../../network/API'
import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'

const UserCreate = () => {
    const [form] = Form.useForm()

    const fields = [
        {
            name: ['name'],
            label: 'Name',
            row: 1,
            col: 1,
            size: 2,
        }, {
            name: ['username'],
            label: 'Username',
            row: 1,
            col: 3,
            size: 2,
        }, {
            name: ['email'],
            label: 'Email',
            row: 2,
            col: 1,
            size: 2,
        }, {
            name: ['role'],
            label: 'Role',
            type: 'select',
            options: [
                {
                    key: 'user',
                    label: 'User'
                }, {
                    key: 'admin',
                    label: 'Admin'
                }
            ],
            row: 2,
            col: 3,
            size: 2,
        }, {
            name: ['password'],
            label: 'Password',
            type: 'password',
            row: 3,
            col: 1,
            size: 2,
        }
    ]

    return (
        <PageContent
            title={'Create user'}
        >
            <FormBlock
                form={form}
                fields={fields}
                onSuccess={'/users/list'}
                onCancel={'/users/list'}
                request={createUser}
            ></FormBlock>
        </PageContent>
    )
}

export default UserCreate