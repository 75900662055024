import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Form } from 'antd'
import moment from 'moment/moment'

import { AppContext } from '../../context/AppContext'
import { getClaim, getClaimTypes, getCurrencies, updateClaim, getUsers } from '../../network/API'
import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

const ClaimEdit = () => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    const { id } = useParams()

    const fields = [
        {
            name: ['user'],
            label: 'Username',
            disabled: true,
            type: 'select',
            requestOption: getUsers,
            row: 1,
            col: 1,
            size: 2,
        }, {
            name: ['createdAt'],
            label: 'Submit date',
            type: 'date',
            disabled: true,
            row: 1,
            col: 3,
            size: 1,
        }, {
            name: ['name'],
            label: 'Claim name',
            row: 2,
            col: 1,
            size: 2,
        }, {
            name: ['currency'],
            label: 'Currency',
            type: 'select',
            requestOption: getCurrencies,
            row: 2,
            col: 3,
            size: 1,
        }, {
            name: ['totalPrice'],
            label: 'Total price',
            disabled: true,
            row: 2,
            col: 4,
            size: 1,
        }, {
            name: ['claimDetails'],
            label: 'Claim',
            canAdd: true,
            deleteBtn: 'Delete',
            addBtn: 'Add one claim',
            fields: [
                {
                    name: ['type'],
                    label: 'Claim type',
                    type: 'select',
                    requestOption: getClaimTypes,
                    row: 1,
                    col: 1,
                    size: 2,
                }, {
                    name: ['date'],
                    label: 'Transaction date',
                    type: 'date',
                    row: 1,
                    col: 3,
                    size: 1,
                }, {
                    name: ['price'],
                    label: 'Price',
                    type: 'number',
                    isCalculation: true,
                    variableName: ['totalPrice'],
                    calculation: (claim) => {
                        let total = 0
                        for (const i in claim.claimDetails) {
                            total += parseFloat(claim.claimDetails[i].price).toFixed(2)
                        }
                        total = parseFloat(total).toFixed(2)
                        return total
                    },
                    row: 1,
                    col: 4,
                    size: 1,
                }, {
                    name: ['description'],
                    label: 'Description',
                    type: 'text',
                    row: 2,
                    col: 1,
                    size: 4,
                }, {
                    name: ['file'],
                    label: 'File',
                    type: 'upload',
                    requestURI: '/files/fapiao',
                    storageURI: '/storage/fapiao',
                    row: 3,
                    col: 1,
                    size: 2,
                }
            ],
            row: 3,
            col: 1,
            size: 6,
        }, {
            name: ['status'],
            label: 'Status',
            type: 'select',
            options: [
                {
                    key: 'approved',
                    label: 'Approved'
                }, {
                    key: 'pending',
                    label: 'Pending'
                }, {
                    key: 'rejected',
                    label: 'Rejected'
                }, {
                    key: 'closed',
                    label: 'Closed'
                }
            ],
            row: 4,
            col: 1,
            size: 1,
        }
    ]

    const details = useQuery(
        [
            'getClaim',
            {
                id: id,
                token: token.token,
            },
        ],
        getClaim
    )

    const formatData = (data) => {
        let claim = data
        claim.createdAt = moment(data?.createdAt)
        for (const i in claim.claimDetails) {
            claim.claimDetails[i].date = moment(claim.claimDetails[i].date)
        }

        return claim
    }

    const cleanBeforeSending = (oldData) => {
        for (const i in oldData.claimDetails) {
            if (oldData.claimDetails[i].file) {
                let tempFile = oldData.claimDetails[i].file.fileList
                    ? oldData.claimDetails[i].file?.fileList
                    : oldData.claimDetails[i].file
        
                if (!Array.isArray(tempFile)) {
                    tempFile = [tempFile]
                }
                const newFile = tempFile.map((file) =>
                    file.response ? file.response : file
                )
        
                oldData.claimDetails[i].file = newFile.length ? newFile[0] : {}
            } else {
                oldData.claimDetails[i].file = {}
            }

            oldData.claimDetails[i].price = parseFloat(oldData.claimDetails[i].price).toFixed(2)
        }

        return oldData
    }

    const checkValidity = (values) => {
        if (values.claimDetails.length < 1) {
            return 'No less than 1 claim'
        }

        let isOkFile = true
        values.claimDetails.map((claim) => 
            JSON.stringify(claim.file) === '{}' ? isOkFile = false : null
        )
        if (!isOkFile) {
            return 'Missing fapiao'
        }

        return
    }

    return (
        <PageContent
            title={'Claim detail'}
        >
            {details.isError ? (
                <Error retry={() => details.refetch()} />
            ) : details.isLoading ? (
                <Loader />
            ) : (
                <FormBlock
                    id={id}
                    form={form}
                    fields={fields}
                    onSuccess={'/claims/list'}
                    onCancel={'/claims/list'}
                    data={formatData(details.data)}
                    request={updateClaim}
                    cleanBeforeSending={cleanBeforeSending}
                    checkValidity={checkValidity}
                    invalidate={[
                        'getClaim',
                        'getClaimTypes',
                        'getCurrencies',
                        'getUsers'
                    ]}
                ></FormBlock>
            )}
        </PageContent>
    )
}

export default ClaimEdit