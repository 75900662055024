import { useContext, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { Form, Input, Button, Select, Upload, DatePicker, InputNumber, Typography } from 'antd'
import { UploadOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons'

import { SERVER } from '../network/API'
import { AppContext } from '../context/AppContext'

const FormField = ({
    field,
    form,
    sectionKey = [],
    prevName = [],
    customToken = null,
    ...restField
}) => {
    const { token } = useContext(AppContext)
    const inputRef = useRef(null)
    const dynamicOptionConfig = [
        [
            `${field.requestOption}`,
            {
                token: customToken? customToken:token.token,
            },
        ],
        field.requestOption,
    ]

    useEffect(() => {
        if (inputRef?.current && field.autoFocus) {
            inputRef.current.focus({
                cursor: 'start',
            })
        }
    }, [])

    const requestProps = field.requestOption !== undefined
        ? dynamicOptionConfig
        : ['', () => { }, { enabled: false }]

    const options = useQuery(...requestProps)

    const formProps = {
        name: [...prevName, ...field.name],
        label: field.label,
        rules: [
            {
                required: field.required,
                message: 'Field required',
            },
        ],
    }

    switch (field.type) {
        case 'select':
            return (
                <Form.Item {...formProps}>
                    <Select
                        ref={inputRef}
                        showSearch
                        filterOption={(input, option) => {
                            const label = option.children

                            if (typeof label === 'string') {
                                return (
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            } else {
                                return (
                                    label.props.children[0]
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                )
                            }
                        }}
                        defaultValue={field.default}
                        loading={options.isLoading}
                        disabled={field.disabled}
                        onChange={(e) => {
                            if (!field.onChange) {
                                return null
                            }

                            return field.onChange(
                                form,
                                formProps.name,
                                e,
                                options.data?.find((o) => o.id === e)
                            )
                        }}
                    >
                        {field.options?.map((option) => (
                            <Select.Option key={option.key} value={option.key}>
                                {option.label}
                            </Select.Option>
                        ))}
                        {options.data?.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            )
        case 'upload':
            return (
                <UploadFile
                    {...restField}
                    {...formProps}
                    form={form}
                    field={field}
                    token={token.token}
                    sectionKey={sectionKey}
                />
            )
        case 'date':
            return (
                <Form.Item {...restField} {...formProps}>
                    <DatePicker
                        disabled={field.disabled}
                        disabledDate={
                            field.disabledDate
                                ? (date) => field.disabledDate(date, form)
                                : null
                        }
                        defaultValue={field.default}
                        format={'DD/MM/YYYY'}
                        // format={'YYYY-MM-DD'}
                        allowClear={true}
                    />
                </Form.Item>
            )
        case 'daterange':
            return (
                <Form.Item {...restField} {...formProps}>
                    <DatePicker.RangePicker disabled={field.disabled} />
                </Form.Item>
            )
        case 'text':
            return (
                <Form.Item {...restField} {...formProps}>
                    <Input.TextArea
                        rows={4}
                        disabled={field.disabled}
                        autoSize={{ minRows: 4, maxRows: 6 }}
                    />
                </Form.Item>
            )
        case 'number':
            return (
                <Form.Item {...restField} {...formProps}>
                    <InputNumber
                        ref={inputRef}
                        min={field.min || 0}
                        max={field.max}
                        formatter={(e) => {
                            let num

                            if (field.isInt) {
                                num = parseInt(e)
                            } else {
                                num = parseFloat(e)
                            }
                            if (isNaN(num)) {
                                return ''
                            }
                            return num
                        }}
                        onChange={(e) => {
                            if (field.isCalculation) {
                                if (field.calculation) {
                                    let data = form?.getFieldsValue(true)
                                    form?.setFieldValue([...field.variableName], field.calculation(data))
                                }
                            }

                            if (!field.onChange) {
                                return null
                            }

                            return field.onChange(form, formProps.name, e)
                        }}
                        placeholder={field.placeholder}
                        disabled={field.disabled}
                        defaultValue={field.default}
                        step={0.01}
                    />
                </Form.Item>
            )
        case 'password':
            return (
                <Form.Item {...restField} {...formProps}>
                    <Input.Password
                        disabled={field.disabled}
                        placeholder="**********"
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            )
        case 'link':
            return (
                <Typography.Link href={field.pathname} target="_blank">
                    {field.label}
                </Typography.Link>
            )
        case 'button':
            return (
                <Button onClick={field.onClick}>
                    {field.label}
                </Button>
            )
        default:
            return (
                <Form.Item {...restField} {...formProps}>
                    <Input
                        ref={inputRef}
                        placeholder={field.placeholder}
                        disabled={field.disabled}
                        defaultValue={field.default}
                    />
                </Form.Item>
            )
    }
}

const UploadFile = ({
    field,
    form,
    token,
    sectionKey = [],
    ...restField
}) => {

    const uploadProps = {
        name: 'file',
        action: `${SERVER.baseURL}${field.requestURI}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }

    const getFileList = () => {
        let savedPicture = form?.getFieldValue([...sectionKey, ...restField.name])

        savedPicture = savedPicture?.fileList
            ? savedPicture.fileList
            : savedPicture

        if (savedPicture) {
            if (!Array.isArray(savedPicture)) {
                savedPicture = [savedPicture]
            }

            return savedPicture.map((pic, i) => ({
                uid: '-' + (i + 1),
                name: pic.name,
                status: 'done',
                // uri: pic.uri,
                url: `${SERVER.baseURL}${field.storageURI}/${pic.uri}`,
                thumbUrl: `${SERVER.baseURL}${field.storageURI}/${pic.uri}`,
                response: pic,
            }))
        }

        return []
    }

    return (
        <Form.Item
            {...restField}
            valuePropName={field.name}
        >
            <Upload
                {...uploadProps}
                maxCount={1}
                listType="text"
                disabled={field.disabled}
                defaultFileList={getFileList()}
            >
                <Button disabled={field.disabled} icon={<UploadOutlined />}>
                    Upload Photo
                </Button>
            </Upload>
        </Form.Item>
    )
}

export default FormField