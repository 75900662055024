import { useState } from 'react'
import { Form } from 'antd'

import { resetPassword } from '../../network/API'
import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'

const ResetPassword = () => {
    const [form] = Form.useForm()
    const [token, setToken] = useState(window.location.search.slice(7))

    const fields = [
        {
            name: ['password'],
            label: 'New password',
            type: 'password',
            row: 1,
            col: 1,
            size: 2,
        }, {
            name: ['confirmPassword'],
            label: 'Confirm New password',
            type: 'password',
            row: 2,
            col: 1,
            size: 2,
        }
    ]

    const checkValidity = (data) => {
        if (!data.password || !data.confirmPassword) {
            return 'Please enter password'
        }

        if (data.password !== data.confirmPassword) {
            return 'Please check new password'
        }

        return false
    }

    return (
        <PageContent
            title={'Reset password'}
        >
            <FormBlock
                form={form}
                fields={fields}
                onSuccess={'/'}
                onCancel={'/'}
                request={resetPassword}
                checkValidity={checkValidity}
                customToken={token}
            ></FormBlock>
        </PageContent>
    )
}

export default ResetPassword