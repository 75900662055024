import { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Layout } from 'antd'

import SideBar from './SideBar'
import menuList from '../constants/Menu'

// Claim
import Claims from '../pages/claims/Claims'

const PageLayout = () => {
    const [collapsed, setCollapsed] = useState(false)
    const onCollapse = (collapsed) => setCollapsed(collapsed)

    return (
        <Router>
            <Layout
                style={{
                    minHeight: '100vh',
                }}
            >
                <SideBar
                    collapsed={collapsed}
                    onCollapse={onCollapse}
                ></SideBar>

                <Layout>
                    <Routes>
                        <Route
                            path="/"
                            element={<Claims />}>
                        </Route>

                        {menuList.map((menu) => (
                            <>
                                <Route
                                    path={menu.path}
                                    element={menu.component}
                                >
                                </Route>
                                {
                                    menu?.children?.map((route) => (
                                        <Route
                                            path={route.path}
                                            element={route.component}
                                        >
                                        </Route>
                                    ))
                                }
                                {
                                    menu?.ext?.map((route) => (
                                        <Route
                                            path={route.path}
                                            element={route.component}
                                        >
                                        </Route>
                                    ))
                                }
                            </>
                        ))}
                        <Route element={<Claims />} />
                    </Routes>
                </Layout>

            </Layout>
        </Router>
    )
}

export default PageLayout