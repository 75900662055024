import { useReducer, useEffect, useState } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'

import { refresh } from './network/API'

import { AppContext } from './context/AppContext'
import { userReducer, tokenReducer, LOGIN, LOGOUT, TOKEN_SET } from './reducers/Reducers'
import Login from './pages/Login'
import PageLayout from './components/PageLayout'

const queryClient = new QueryClient()

const App = () => {
	const [user, dispatchUser] = useReducer(userReducer, null)
	const [token, dispatchToken] = useReducer(tokenReducer, null)
	const [refreshToken, dispatchRefreshToken] = useReducer(tokenReducer, null)
	const [reset, setReset] = useState(false)

	useEffect(() => {
		const savedUser = JSON.parse(localStorage.getItem('slingshotUser'))
		const savedToken = JSON.parse(localStorage.getItem('slingshotToken'))
		const savedRefreshToken = JSON.parse(localStorage.getItem('slingshotRefreshToken'))

		if (window.location.pathname === '/auth/reset') {
			setReset(true)
		}

		// checking if token is expired
		if (
			savedUser &&
			savedToken &&
			new Date() <= new Date(savedToken.expireAt)
		) {
			dispatchUser({ type: LOGIN, payload: savedUser })
			dispatchToken({
				type: TOKEN_SET,
				payload: savedToken,
			})
		} else if (
			savedUser &&
			savedToken &&
			savedRefreshToken &&
			new Date() <= new Date(savedRefreshToken.expireAt)
		) {
			refresh(refreshToken)
				.then((data) => {
					dispatchRefreshToken({
						type: TOKEN_SET,
						payload: {
							token: data.token,
							expireAt: data.expireAt,
						},
					})
				})
				.catch(() => {
					localStorage.clear()
					dispatchUser({ type: LOGOUT })
				})
		} else {
			localStorage.clear()
		}
	}, [])

	return (
		<AppContext.Provider
			value={{
				user,
				dispatchUser,
				token,
				dispatchToken,
				refreshToken,
				dispatchRefreshToken,
			}}
		>
			<QueryClientProvider client={queryClient}>
				<ConfigProvider
					locale={enUS}
				>
					<div className="App">
						{user || reset ? <PageLayout /> : <Login />}
					</div>
				</ConfigProvider>
				<ReactQueryDevtools initialIsOpen={false} position="top-left" />
			</QueryClientProvider>
		</AppContext.Provider>
	)
}

export default App;
