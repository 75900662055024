const axios = require('axios')

export const SERVER = { baseURL: process.env.REACT_APP_API_URL }

export const INSTANCE = axios.create(SERVER)
export const INSTANCELIST = axios.create(SERVER)

INSTANCE.interceptors.response.use(async (response) => {
    // console.log('Response:', response)

    return response.data
}, (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear()
        window.location.href = '/'
    }
    return Promise.reject(error)
})

INSTANCELIST.interceptors.response.use(async (response) => {
    // console.log('Response:', response)

    return {
        range: response.headers['content-range']?.split('/')[1],
        list: response.data,
    }
}, (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear()
        window.location.href = '/'
    }
    return Promise.reject(error)
})

export const login = (username, password) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: '/auth/login',
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                username: username,
                password: password,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const refresh = (refreshToken) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: '/auth/login',
            headers: {
                'Content-Type': 'application/json',
            },
            params: {
                refreshToken,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getClaims = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, filter } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: '/claims/details',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                disabled: false,
                filter: JSON.stringify(filter),
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const getClaim = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, id } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/claims/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const updateClaim = (token, data, id) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'put',
            url: `/claims/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const getUsers = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: '/users',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            // params: {
            //     disabled: false,
            // }
        })
            .then(resolve)
            .catch(reject)
    })

export const getUser = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, id } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/users/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {},
        })
            .then(resolve)
            .catch(reject)
    })

export const createUser = (token, data) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: '/users',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const updateUser = (token, data, id) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'put',
            url: `/users/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const sendResetEmail = (data) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: '/auth/password',
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const resetPassword = (token, data) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: `/auth/password/${token}`,
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const updatePassword = (token, data, id) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'put',
            url: `/users/${id}/password`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const getCurrencies = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: '/currencies',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                disabled: false,
            }
        })
            .then(resolve)
            .catch(reject)
    })

export const getCurrency = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, id } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/currencies/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const createCurrency = (token, data) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: '/currencies',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const updateCurrency = (token, data, id) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'put',
            url: `/currencies/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const getClaimTypes = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: '/claim-types',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                disabled: false,
            }
        })
            .then(resolve)
            .catch(reject)
    })

export const getClaimType = ({ queryKey }) =>
    new Promise((resolve, reject) => {
        const { token, id } = queryKey[1]

        INSTANCE({
            method: 'get',
            url: `/claim-types/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(resolve)
            .catch(reject)
    })

export const createClaimType = (token, data) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'post',
            url: '/claim-types',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })

export const updateClaimType = (token, data, id) =>
    new Promise((resolve, reject) => {

        INSTANCE({
            method: 'put',
            url: `/claim-types/${id}`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: data,
        })
            .then(resolve)
            .catch(reject)
    })