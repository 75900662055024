import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import { AppContext } from '../context/AppContext'
import { LOGOUT } from '../reducers/Reducers'
import menuList from '../constants/Menu'

const SideBar = ({
    collapsed,
    onCollapse
}) => {
    const { user, dispatchUser } = useContext(AppContext)

    const renderMenu = (data) => {
        return data.map(item => {
            if (item.children) {
                return (
                    <Menu.SubMenu
                        title={<span>{item.label}</span>}
                        key={item.key}
                        icon={item.icon}
                    >
                        {renderMenu(item.children)}
                    </Menu.SubMenu>
                )
            }

            return (
                <Menu.Item
                    title={item.label}
                    key={item.key}
                    icon={item.icon}
                >
                    <Link
                        to={item.path}
                    >
                        {item.label}
                    </Link>
                </Menu.Item>
            )
        })
    }

    return user?.role && (
        <Layout.Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            width={260}
        >
            <Layout>
                <Layout.Header
                    className='logo'
                >
                    {collapsed ? (
                        <img
                            src={require('../images/logo_square_white.png')}
                            alt='icon'
                        />
                    ) : (
                        <img
                            src={require('../images/logo_full_white.png')}
                            alt='icon'
                        />
                    )}
                </Layout.Header>

                <Layout.Content>
                    <Menu
                        theme='dark'
                        mode='inline'
                    >
                        {renderMenu(menuList)}
                        <Menu.Item
                            onClick={() => dispatchUser({ type: LOGOUT })}
                            key="logout"
                            icon={<LogoutOutlined />}
                        >
                            Log out
                        </Menu.Item>
                    </Menu>
                </Layout.Content>
            </Layout>
        </Layout.Sider>
    )
}

export default SideBar
