import { Fragment } from 'react'
import { Form, Button, Card } from 'antd'

import FormField from './FormField'

const FormList = ({
    form,
    section,
}) => {

    return (
        <Form.List
            name={section.name}
        >
            {(fields, { add, remove }) => (
                <div
                    style={{
                        gridColumn: `${section.col} / ${section.col + section.size}`,
                        gridRow: section.row,
                    }}
                >
                    {fields.map(
                        ({ key, name, ...restField }, index) => (
                            <Fragment key={key}>
                                <Card
                                    title={`${section.label} ${index + 1}`}
                                    style={{
                                        gridColumn: '1 / 7'
                                    }}
                                >
                                    <div
                                        className="float-btn"
                                        style={{
                                            display: 'flex',
                                        }}
                                    >
                                        <Button
                                            onClick={() => remove(name)}
                                            type="link"
                                        >
                                            {section.deleteBtn}
                                        </Button>
                                    </div>
                                    <div className="claim-card">
                                        {section.fields.map((field, i) => (
                                            <div
                                                style={{
                                                    gridColumn: `${field.col} / ${field.col + field.size}`,
                                                    gridRow: field.row,
                                                }}
                                            >
                                                <FormField
                                                    form={form}
                                                    field={field}
                                                    style={{
                                                        flexGlow: field.size
                                                    }}
                                                    prevName={[
                                                        index
                                                    ]}
                                                    sectionKey={[
                                                        ...section.name
                                                    ]}
                                                ></FormField>
                                            </div>
                                        ))}
                                    </div>
                                </Card>
                            </Fragment>
                        )
                    )}
                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            JSON.stringify(prevValues) !==
                            JSON.stringify(currentValues)
                        }
                    >
                        {({ getFieldValue }) => {
                            return (
                                <Button
                                    style={{
                                        marginTop: 16
                                    }}
                                    onClick={() => add()}
                                >
                                    {section.addBtn}
                                </Button>
                            )
                        }}
                    </Form.Item>
                </div>
            )}
        </Form.List>
    )
}

export default FormList