import { useParams } from 'react-router-dom'
import { Form } from 'antd'

import { updatePassword } from '../../network/API'
import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'

const ChangePassword = () => {
    const [form] = Form.useForm()
    const { id } = useParams()

    const fields = [
        {
            name: ['password'],
            label: 'Actual password',
            type: 'password',
            row: 1,
            col: 1,
            size: 2,
        }, {
            name: ['newPassword'],
            label: 'New password',
            type: 'password',
            row: 2,
            col: 1,
            size: 2,
        }, {
            name: ['confirmPassword'],
            label: 'Confirm New password',
            type: 'password',
            row: 3,
            col: 1,
            size: 2,
        }
    ]

    const checkValidity = (data) => {
        if (!data.password || !data.newPassword || !data.confirmPassword) {
            return 'Please enter password'
        }

        if (data.newPassword !== data.confirmPassword) {
            return 'Please check new password'
        }

        return false
    }

    return (
        <PageContent
            title={'Change password'}
        >
            <FormBlock
                id={id}
                form={form}
                fields={fields}
                onSuccess={'/users/list'}
                onCancel={'/users/list'}
                request={updatePassword}
                checkValidity={checkValidity}
            ></FormBlock>
        </PageContent>
    )
}

export default ChangePassword