import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { Form, message } from 'antd'

import { AppContext } from '../../context/AppContext'
import { getUser, updateUser, sendResetEmail } from '../../network/API'
import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'
import Loader from '../../components/Loader'
import Error from '../../components/Error'

const UserEdit = () => {
    const { token } = useContext(AppContext)
    const [form] = Form.useForm()
    const { id } = useParams()

    const fields = [
        {
            name: ['name'],
            label: 'Name',
            row: 1,
            col: 1,
            size: 2,
        }, {
            name: ['username'],
            label: 'Username',
            row: 1,
            col: 3,
            size: 2,
        }, {
            name: ['email'],
            label: 'Email',
            row: 2,
            col: 1,
            size: 2,
        }, {
            name: ['role'],
            label: 'Role',
            type: 'select',
            options: [
                {
                    key: 'user',
                    label: 'User'
                }, {
                    key: 'admin',
                    label: 'Admin'
                }
            ],
            row: 2,
            col: 3,
            size: 2,
        }, {
            name: ['password'],
            label: 'Password',
            disabled: true,
            row: 3,
            col: 1,
            size: 2,
        }, {
            name: [''],
            label: 'Send reset email',
            type: 'button',
            onClick: () => {
                let user = form.getFieldsValue(true)
                sendResetEmail(
                    {
                        email: user.email
                    }
                ).then((data) => {
                    message.success('Send successfully')
                })
            },

            row: 4,
            col: 1,
            size: 1,
        }
    ]

    const details = useQuery(
        [
            'getUser',
            {
                id: id,
                token: token.token,
            },
        ],
        getUser
    )

    const formatData = (data) => {
        return {
            ...data,
            password: '********'
        }
    }

    const cleanBeforeSending = (oldData) => {
        Reflect.deleteProperty(oldData, 'password')

        return oldData
    }

    return (
        <PageContent
            title={'Edit user'}
        >
            {details.isError ? (
                <Error retry={() => details.refetch()} />
            ) : details.isLoading ? (
                <Loader />
            ) : (
                <FormBlock
                    id={id}
                    form={form}
                    fields={fields}
                    onSuccess={'/users/list'}
                    onCancel={'/users/list'}
                    data={formatData(details.data)}
                    request={updateUser}
                    cleanBeforeSending={cleanBeforeSending}
                    invalidate={[
                        'getUser'
                    ]}
                ></FormBlock>
            )}
        </PageContent>
    )
}

export default UserEdit