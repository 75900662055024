import { Form } from 'antd'

import { createCurrency } from '../../network/API'
import PageContent from '../../components/PageContent'
import FormBlock from '../../components/FormBlock'

const CurrencyCreate = () => {
    const [form] = Form.useForm()

    const fields = [
        {
            name: ['name'],
            label: 'Name',
            row: 1,
            col: 1,
            size: 2,
        }
    ]

    return (
        <PageContent
            title={'Create value'}
        >
            <FormBlock
                form={form}
                fields={fields}
                onSuccess={'/currencies/list'}
                onCancel={'/currencies/list'}
                request={createCurrency}
            ></FormBlock>
        </PageContent>
    )
}

export default CurrencyCreate