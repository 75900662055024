import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Button, Table, Popconfirm } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

import { AppContext } from '../../context/AppContext'
import { getUsers, updateUser } from '../../network/API'
import PageContent from '../../components/PageContent'
import Error from '../../components/Error'
import TableBlock from '../../components/TableBlock'

const Users = () => {
    const { token } = useContext(AppContext)
    const navigate = useNavigate()

    const users = useQuery(
        [
            'getUsers',
            {
                token: token.token,
            },
        ],
        getUsers
    )

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'user',
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (role) => {
                const roleList = {
                    'super-admin': 'Super admin',
                    'admin': 'Admin',
                    'user': 'User'
                }
                return roleList[role]
            }
        },
        {
            title: 'Password',
            dataIndex: 'password',
            key: 'password',
            render: (entity) => '********',
        },
        {
            title: 'Blocked',
            dataIndex: 'disabled',
            key: 'disabled',
            render: (entity) => entity ? <CheckOutlined /> : null,
        },
        {
            title: '',
            key: 'actions',
            render: (entity) => (
                <Button
                    type="link"
                    size='small'
                    onClick={() => navigate(`/users/${entity.id}`)}
                >
                    Edit
                </Button>
            )
        },
        {
            title: '',
            key: 'disable',
            render: (entity) => !entity.disabled ? (
                <Popconfirm
                    title='Are you sure to disable this user?'
                    onConfirm={() => handleActions(entity, true)}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        type="link"
                        size='small'
                    >Disable</Button>
                </Popconfirm>
            ) : (
                <Popconfirm
                    title='Are you sure to activate this user?'
                    onConfirm={() => handleActions(entity, false)}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        type="link"
                        size='small'
                    >Activate</Button>
                </Popconfirm>
            )
        }
    ]

    const handleActions = (data, status) => {
        updateUser(
            token.token,
            {
                email: data.email,
                disabled: status
            },
            data.id
        ).then((result) => {
            window.location.reload()
        })
    }

    return (
        <PageContent
            title={'User list'}
            headerActions={[
                {
                    label: 'Create user',
                    onClick: () => {
                        navigate('/users/create')
                    },
                }
            ]}
        >
            {users.isError ? (
                <Error retry={() => users.refetch()} />
            ) : (
                <TableBlock
                    columns={columns}
                    dataSource={users?.data}
                    loading={users.isLoading}
                />
            )}
        </PageContent>
    )
}

export default Users