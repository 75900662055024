import { BarsOutlined, UserOutlined, DatabaseOutlined, ExportOutlined } from '@ant-design/icons';

// Claim
import Claims from '../pages/claims/Claims'
import ClaimEdit from '../pages/claims/ClaimEdit'

// User
import Users from '../pages/users/Users'
import UserCreate from '../pages/users/UserCreate'
import UserEdit from '../pages/users/UserEdit'
import ChangePassword from '../pages/users/ChangePassword'
import ResetPassword from '../pages/users/ResetPassword'

// Currency
import Currencies from '../pages/currencies/Currencies'
import CurrencyCreate from '../pages/currencies/CurrencyCreate'
import CurrencyEdit from '../pages/currencies/CurrencyEdit'

// Claim type
import ClaimTypes from '../pages/claimTypes/ClaimTypes'
import ClaimTypeCreate from '../pages/claimTypes/ClaimTypeCreate'
import ClaimTypeEdit from '../pages/claimTypes/ClaimTypeEdit'

// Export
import ExportCSV from '../pages/ExportCSV'

const menuList = [
    {
        label: 'Claim List',
        key: 'claims',
        path: '/claims/list',
        icon: <BarsOutlined />,
        component: <Claims />,
        ext: [
            {
                path: '/claims/:id',
                component: <ClaimEdit />,
            }
        ],
    },
    {
        label: 'Users',
        key: 'users',
        path: '/users/list',
        icon: <UserOutlined />,
        component: <Users />,
        ext: [
            {
                path: '/users/create',
                component: <UserCreate />,
            }, {
                path: '/users/:id',
                component: <UserEdit />,
            }, {
                path: '/users/:id/password',
                component: <ChangePassword />,
            }, {
                path: '/auth/reset',
                component: <ResetPassword />,
            }
        ],
    },
    {
        label: 'Fields',
        key: 'fields',
        icon: <DatabaseOutlined />,
        children: [
            {
                label: 'Claim Type',
                key: 'claimType',
                path: '/claim-types/list',
                component: <ClaimTypes />,
            },
            {
                label: 'Currency',
                key: 'currency',
                path: '/currencies/list',
                component: <Currencies />,
            }
        ],
        ext: [
            {
                path: '/currencies/create',
                component: <CurrencyCreate />,
            }, {
                path: '/currencies/:id',
                component: <CurrencyEdit />,
            }, {
                path: '/claim-types/create',
                component: <ClaimTypeCreate />,
            }, {
                path: '/claim-types/:id',
                component: <ClaimTypeEdit />,
            }
        ],
    },
    {
        label: 'CSV Export Settings',
        key: 'csv',
        path: '/csv',
        icon: <ExportOutlined />,
        component: <ExportCSV />,
    }
]

export default menuList
