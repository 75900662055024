import { useContext } from 'react'
import { useQueryClient, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Form, message, Button, Space } from 'antd'

import { AppContext } from '../context/AppContext'
import FormField from './FormField'
import FormList from './FormList'

const FormBlock = ({
    id,
    request,
    form,
    fields,
    onSuccess,
    onCancel,
    invalidate,
    success = 'Successfuly saved',
    data,
    cleanBeforeSending,
    checkValidity,
    customToken = null,
}) => {
    const { token } = useContext(AppContext)
    const [defaultForm] = Form.useForm()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const formIntance = form ? form : defaultForm

    const mutation = useMutation((values) => request(...values), {
        onSuccess: (data, variables, context) => {
            message.success(success)
            if (invalidate) {
                for (const key of invalidate) {
                    queryClient.invalidateQueries(key)
                }
            }
            if (onSuccess) {
                navigate(onSuccess)
            }
        },
        onError: (error, variables, context) => {
            console.log(error)
            message.error(error?.response?.data?.message || 'An error occurs')
        },
    })

    const onCancelClick = () => {
        if (onCancel) {
            navigate(onCancel)
        } else {
            formIntance.resetFields()
        }
    }

    const onFinish = (values) => {
        let newVals = JSON.parse(JSON.stringify(values))

        if (cleanBeforeSending) {
            newVals = cleanBeforeSending(newVals)
        }

        if (checkValidity) {
            let isNotOkay = checkValidity(newVals)
            if (isNotOkay) {
                message.error(isNotOkay)
                return
            }
        }

        mutation.mutate([customToken ? customToken : token.token, newVals, id])
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Form
            form={formIntance}
            colon={false}
            initialValues={data}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            {
                fields && fields.map((field, index) => {
                    if (field.canAdd) {
                        return (
                            <FormList
                                form={formIntance}
                                section={field}
                            ></FormList>
                        )
                    } else {
                        return (
                            <div
                                style={{
                                    gridColumn: `${field.col} / ${field.col + field.size}`,
                                    gridRow: field.row,
                                }}
                            >
                                <FormField
                                    form={form}
                                    field={field}
                                    style={{
                                        flexGlow: field.size
                                    }}
                                    customToken={customToken}
                                ></FormField>
                            </div>
                        )
                    }
                })
            }

            <Form.Item
                style={{
                    gridColumn: '1 / 2',
                }}
            >
                <Space
                    style={{
                        display: 'flex',
                        // marginTop: 24,
                    }}
                    align="baseline"
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={mutation.isLoading}
                    >
                        {mutation.isLoading ? 'Sending' : 'Save'}
                    </Button>
                    <Button
                        onClick={onCancelClick}
                        htmlType="button"
                        disabled={mutation.isLoading}
                    >
                        Cancel
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    )
}

export default FormBlock