import { useContext } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Button, Table, Popconfirm } from 'antd'
import moment from 'moment/moment'

import { AppContext } from '../../context/AppContext'
import { getClaimTypes, updateClaimType } from '../../network/API'
import PageContent from '../../components/PageContent'
import Error from '../../components/Error'
import TableBlock from '../../components/TableBlock'

const ClaimTypes = () => {
    const { token } = useContext(AppContext)
    const navigate = useNavigate()

    const claimTypes = useQuery(
        [
            'getClaimTypes',
            {
                token: token.token,
            },
        ],
        getClaimTypes
    )

    const columns = [
        {
            title: 'Value',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Created on',
            key: 'createdAt',
            render: (entity) => moment(entity.createdAt).format('DD/MM/YYYY'),
        },
        {
            title: 'Updated on',
            key: 'updatedAt',
            render: (entity) => moment(entity.updatedAt).format('DD/MM/YYYY'),
        },
        {
            title: '',
            key: 'edit',
            render: (entity) => (
                <Button
                    type="link"
                    size='small'
                    onClick={() => navigate(`/claim-types/${entity.id}`)}
                >
                    Edit
                </Button>
            )
        },
        {
            title: '',
            key: 'delete',
            render: (entity) => (
                <Popconfirm
                    title='Are you sure to delete this value?'
                    onConfirm={() => handleDelete(entity.id)}
                    okText='Yes'
                    cancelText='No'
                >
                    <Button
                        type="link"
                        size='small'
                    >Delete</Button>
                </Popconfirm>
            )
        }
    ]

    const handleDelete = (id) => {
        updateClaimType(
            token.token,
            { disabled: true },
            id
        ).then((result) => {
            window.location.reload()
        })
    }
    return (
        <PageContent
            title={'Claim type fields'}
            headerActions={[
                {
                    label: 'Create new value',
                    onClick: () => {
                        navigate('/claim-types/create')
                    },
                }
            ]}
        >
            {claimTypes.isError ? (
                <Error retry={() => claimTypes.refetch()} />
            ) : (
                <TableBlock
                    columns={columns}
                    dataSource={claimTypes.data}
                    loading={claimTypes.isLoading}
                />
            )}
        </PageContent>
    )
}

export default ClaimTypes