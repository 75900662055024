
const LOGIN = 'LOGIN'
const LOGOUT = 'LOGOUT'

const SWITCH = 'SWITCH'

const TOKEN_SET = 'TOKEN_SET'
const TOKEN_RESET = 'TOKEN_RESET'

const userReducer = (state, action) => {
    switch (action.type) {
        case LOGIN:
            return action.payload
        case LOGOUT:
            localStorage.clear()
            return null
        default:
            throw new Error()
    }
}

const tokenReducer = (state, action) => {
    switch (action.type) {
        case TOKEN_SET:
            return action.payload
        case TOKEN_RESET:
            return null
        default:
            throw new Error()
    }
}

export { userReducer, tokenReducer }
export { LOGIN, LOGOUT, SWITCH, TOKEN_SET, TOKEN_RESET }
